import { useEffect, useRef } from 'react'

export const useDocumentTitle = (title, { onceOnly = true, prefix, separator = '-' } = {}) => {
  const initialisedRef = useRef(false)

  useEffect(() => {
    if (initialisedRef.current) {
      return
    }

    if (!document) {
      return
    }

    if (title == null) {
      return
    }

    if (onceOnly) {
      initialisedRef.current = true
    }

    document.title = prefix ? `${prefix} ${separator} ${title}` : title
  }, [title, onceOnly, prefix, separator])
}

export const DocumentTitle = ({ title, onceOnly, prefix }) => {
  useDocumentTitle(title, { onceOnly, prefix })
  return null
}
