import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/database'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'
import 'firebase/compat/functions'
import { ReactReduxFirebaseProvider, firebaseReducer } from 'react-redux-firebase'
import { firestoreReducer, createFirestoreInstance } from 'redux-firestore' // <- needed if using firestore
import { firebaseConfig, FIRESTORE_STORAGE_URL } from './config'
import { configureStore } from '@reduxjs/toolkit'
import { Provider } from 'react-redux'
import { initialState as preferencesInitialState, preferencesReducer } from './preferences'

const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: false,
  enableLogging: false,
  storageBucket: FIRESTORE_STORAGE_URL
}

firebase.initializeApp(firebaseConfig)
firebase.firestore()
firebase.storage()
firebase.functions()

const initialState = {
  ...preferencesInitialState
}

const store = configureStore({
  reducer: {
    firebase: firebaseReducer,
    firestore: firestoreReducer,
    preferences: preferencesReducer
  },
  initialState,
  // TODO: This is not recommended, but firestore has a whole bunch of timestamp objects that aren't serializable which creates a lot of noise
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false
    })
})

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance
}

export const FirebaseProvider = ({ children }) => {
  return (
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>{children}</ReactReduxFirebaseProvider>
    </Provider>
  )
}
