// NOTE: This file is not covered by automated tests - individual routes are tested as required
// We would essentially just be testing a Suspense block and a router, which is not worth the effort

import React, { Suspense, lazy } from 'react'
import { Router, globalHistory } from '@reach/router'

import '../analytics'
import { pageView } from '../analytics'
import { LoadingPage } from 'mds-web-ui'
import { Link } from 'mds-web-ui/components/Link/ReachRouterLink'

const Login = lazy(() => import(/* webpackChunkName: "login" */ './Login'))
const Logout = lazy(() => import(/* webpackChunkName: "logout" */ './Logout'))
const Canvas = lazy(() => import(/* webpackChunkName: "canvas" */ '../components/canvases/Canvas'))
const Collections = lazy(() => import(/* webpackChunkName: "collections" */ './Collections'))

const Home = () => {
  return (
    <Suspense fallback={<LoadingPage LinkComponent={Link} />}>
      <Router>
        <Login path="/login" />
        <Logout path="/logout" />
        <Canvas path="/sample/:canvasId" />
        <Collections default />
      </Router>
    </Suspense>
  )
}

// listen to analytics events
globalHistory.listen(({ location }) => {
  pageView(location.pathname)
})

pageView(globalHistory.location.pathname)

export default Home
