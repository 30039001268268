import React from 'react'
import { ThemeProvider } from './design/ThemeProvider'

import Home from './routes/Home'
import { FirebaseProvider } from './store/FirebaseProvider'
import ErrorBoundary from './utils/ErrorBoundary'

export const App = () => (
  <FirebaseProvider>
    <ThemeProvider>
      <ErrorBoundary>
        <Home />
      </ErrorBoundary>
    </ThemeProvider>
  </FirebaseProvider>
)

export default App
