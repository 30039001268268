import { useEffect } from 'react'
import { environment, FathomToken } from './store/config'
import * as Fathom from 'fathom-client'
import { sanitisePath } from './utils/href'

const testMode = process.env.NODE_ENV === 'test' || environment === 'development'

const FathomEventIDs =
  environment === 'production'
    ? {
        // TODO events
      }
    : // staging
      {
        // TODO events
      }

const log = message => {
  if (testMode || environment === 'staging') {
    // eslint-disable-next-line no-console
    console.log(message)
  }
}

export const initialise = () => {
  if (!FathomToken) return

  if (testMode) {
    // eslint-disable-next-line no-console
    console.log('Skipping Fathom setup, in test mode')
  } else {
    Fathom.load(FathomToken, { auto: false })
  }
}

export const setAnalyticsUser = userId => {
  // Do nothing - Fathom has no userIds
}

export const pageView = location => {
  // sanitise url - remove doc Ids etc
  const url = sanitisePath(location)

  log(`Analytics test mode: Pageview - ${url}`)
  if (testMode) return

  Fathom.trackPageview({ url })
}

export const trackEvent = eventName => {
  const eventId = FathomEventIDs[eventName]

  log(`Analytics test mode: Event - ${eventName} (${eventId})`)
  if (testMode) return

  if (eventId) {
    Fathom.trackGoal(eventId)
  }
}

export const TrackEvent = ({ action: eventName }) => {
  useEffect(() => {
    trackEvent(eventName)
  }, [eventName])
  return null
}

export function* trackEventAsync(eventName) {
  trackEvent(eventName)
  yield
}

initialise()
