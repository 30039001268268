import React from 'react'
import { ErrorPage } from './ErrorPage'

const VITE_DYNAMIC_IMPORT_ERRORS = [
  'Failed to fetch dynamically imported module',
  'Unable to preload CSS'
]

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    let errorMessage = 'Uncaught error:'

    // If the error is related to dynamic imports, see https://bonobo-labs.sentry.io/issues/4506717562/?project=4505751861395456&statsPeriod=14d&stream_index=1
    // This is a workaround for an open issue with vite, see https://github.com/vitejs/vite/issues/11804 for details
    // Just add some additional detail to the error message
    if (VITE_DYNAMIC_IMPORT_ERRORS.some(e => error.message.includes(e))) {
      errorMessage = 'Uncaught error (dynamic imports):'
    }

    // This error will be caught by sentry and thrown
    // eslint-disable-next-line no-console
    console.error(errorMessage, error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <ErrorPage />
    }

    return this.props.children
  }
}

export default ErrorBoundary
