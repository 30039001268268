export const SET_COLLECTION_VIEW_GRID = 'SET_COLLECTION_VIEW_GRID'
export const SET_COLLECTION_VIEW_LIST = 'SET_COLLECTION_VIEW_LIST'
export const TOGGLE_DENSITY = 'TOGGLE_DENSITY'

export const GRID_VIEW = 'grid'
export const LIST_VIEW = 'list'

export const COMFORTABLE_DENSITY = 'comfortable'
export const COMPACT_DENSITY = 'compact'

export const initialState = {
  collectionView: GRID_VIEW,
  density: COMFORTABLE_DENSITY
}

export const preferencesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COLLECTION_VIEW_GRID:
      return {
        ...state,
        collectionView: GRID_VIEW
      }
    case SET_COLLECTION_VIEW_LIST:
      return {
        ...state,
        collectionView: LIST_VIEW
      }
    case TOGGLE_DENSITY:
      return {
        ...state,
        density: state.density === COMFORTABLE_DENSITY ? COMPACT_DENSITY : COMFORTABLE_DENSITY
      }
    default:
      return state
  }
}
