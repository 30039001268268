import { BUILT_IN_COLLECTIONS } from '../components/collections/constants'

export const sanitisePath = url => {
  try {
    // eslint-disable-next-line no-unused-vars
    const [_, path, id] = url.split('/')

    if (path === 'collections') {
      if (!BUILT_IN_COLLECTIONS.includes(id)) {
        // ignore built in collection names
        url = url.replace(id, 'collection-id')
      }
    }
    if (path === 'canvas' || path === 'sample') {
      url = url.replace(id, 'canvas-id')
    }
    if (path === 's') {
      url = url.replace(id, 'share-token')
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error, url)
    return null
  }

  return url
}
